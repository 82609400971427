<template>
  <div v-if="props.priceList?.length" class="elv-valuation-popover-price-list">
    <div v-for="(item, index) in props.priceList" :key="index" class="elv-valuation-popover-price">
      <div class="elv-valuation-popover-price-item">
        <div class="elv-valuation-popover-price-item-left">{{ t('report.priceProvider') }}</div>
        <div class="elv-valuation-popover-price-item-right">
          <div>{{ getProviderName(item) }}</div>
          <PriceIcon
            v-if="['API_EXCHANGE_RATE', 'API_COINGECKO'].includes(item?.priceProvider?.type)"
            :type="item?.priceProvider.type ?? ''"
          />
        </div>
      </div>
      <div class="elv-valuation-popover-price-item">
        <div class="elv-valuation-popover-price-item-left">{{ t('report.priceDatetime') }}</div>
        <div class="elv-valuation-popover-price-item-right">
          {{ dayjs.tz(item?.priceDatetime, 'UTC').format('YYYY/MM/DD HH:mm') }}
        </div>
      </div>

      <div class="elv-valuation-popover-price-item">
        <div class="elv-valuation-popover-price-item-left">{{ t('report.pricePair') }}</div>
        <div class="elv-valuation-popover-price-item-right">{{ item.pricePair }}</div>
      </div>

      <div class="elv-valuation-popover-price-item">
        <div class="elv-valuation-popover-price-item-left">{{ t('common.price') }}</div>
        <div class="elv-valuation-popover-price-item-right">{{ formatNumber(item.price ?? '', 2) }}</div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import dayjs from 'dayjs'
import PriceIcon from './PriceIcon.vue'
import { formatNumber } from '@/lib/utils'
import { JournalPriceType } from '#/TransactionsTypes'

const props = defineProps({
  priceList: {
    type: Array as PropType<JournalPriceType[]>,
    default: () => []
  }
})

const { t } = useI18n()

const getProviderName = (price: JournalPriceType) => {
  switch (price?.priceType) {
    case 'TRADE':
      return 'Trade'
    case 'SALE_COST_BASIS':
      return 'Inventory Cost Basis'
    case 'CUSTOM':
      return 'Customized Price'
    default:
      return price?.priceProvider?.name
  }
}
</script>
<style lang="scss">
.elv-valuation-popover-price-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.elv-valuation-popover-price {
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 10px;
  border-bottom: 1px solid #edf0f3;
  gap: 8px;

  &:last-child {
    padding-bottom: 0px;
    border-bottom: none;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    height: 15px;
    width: 100%;

    &-left {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }

    &-right {
      color: #0e0f11;
      font-family: 'Barlow';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      display: flex;

      img {
        margin-left: 4px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
</style>
