<template>
  <div class="elv-valuation-record-setting" @click="onChangeCostBasisMethod">
    <SvgIcon name="project-setting" width="14" height="14" fill="#838D95" />
    <div class="elv-valuation-record-setting-text">{{ t('project.costBasis.method') }}</div>
  </div>

  <el-dialog
    v-model="showCostBasisMethodDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-valuation-record-method-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-valuation-record-method-dialog-header__title">
        {{ t('title.setCostBasisMethod') }}
      </h4>
    </template>
    <p class="elv-valuation-record-method-dialog-content__title">
      {{ t('title.setCostBasisMethodTitle') }}
    </p>
    <el-form ref="methodFormRef" :model="costBasisForm" :rules="rules">
      <el-form-item prop="method">
        <el-select
          v-model="costBasisForm.method"
          placeholder="Select"
          popper-class="elv-valuation-record-method-select-popper"
        >
          <el-option
            v-for="item in costBasisMethodData"
            :key="item.value"
            :label="transformI18n(item.label)"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button
        type="primary"
        round
        class="elv-valuation-record-method-dialog-footer__delete"
        :loading="saveLoading"
        @click="onSaveCostBasisMethod"
        >{{ t('button.save') }}</el-button
      >
    </template>
  </el-dialog>
</template>
<script lang="ts" setup>
import { transformI18n } from '@/i18n'
import { ElMessage } from 'element-plus'
import ValuationApi from '@/api/ValuationApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'
import { costBasisMethodOptions } from '@/config/reports/index'

const props = defineProps({
  type: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()

const saveLoading = ref(false)
const showCostBasisMethodDialog = ref(false)
const methodFormRef = ref<FormInstance>()
const costBasisForm = reactive({
  method: 'FIFO'
})

const rules = reactive<FormRules>({
  method: [
    {
      required: true,
      trigger: 'blur',
      message: 'Please select the cost basis method.'
    }
  ]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const costBasisMethodData = computed(() => {
  return props.type === 'CRYPTO'
    ? costBasisMethodOptions.filter((item) => item.value !== 'SPECIFIC_ID')
    : costBasisMethodOptions
})

const onCheckCostBasisMethodDialog = () => {
  showCostBasisMethodDialog.value = !showCostBasisMethodDialog.value
}

const onChangeCostBasisMethod = () => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role)) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  onCheckCostBasisMethodDialog()
}

const onSaveCostBasisMethod = async () => {
  try {
    saveLoading.value = true
    if (props.type === 'CRYPTO') {
      await ValuationApi.changeFairValueCostBasisMethodology(entityId.value, {
        fairValueCostBasisMethodology: costBasisForm.method
      })
    } else {
      await ValuationApi.changeInventoryCostBasisMethodology(entityId.value, {
        fairValueCostBasisMethodology: costBasisForm.method
      })
    }
    ElMessage.success(t('message.saveSuccess'))
    showCostBasisMethodDialog.value = false
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

watch(
  () => showCostBasisMethodDialog.value,
  (value) => {
    if (value) {
      costBasisForm.method =
        props.type === 'CRYPTO'
          ? entityStore.entityDetail?.fairValueCostBasisMethodology
          : entityStore.entityDetail?.inventoryCostBasisMethodology
    }
  }
)
</script>

<style lang="scss">
.elv-valuation-record-setting {
  display: flex;
  align-items: flex-start;
  align-items: center;
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 35px;
  right: 32px;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
  }

  &:hover {
    svg {
      fill: #1343bf;
    }

    .elv-valuation-record-setting-text {
      color: #1343bf;
    }
  }

  &-text {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.elv-valuation-record-method-dialog {
  width: 480px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    color: #0e0f11;
    line-height: 24px;
    height: 49px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 800;

    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: flex-end;
    justify-content: center;

    .elv-valuation-record-method-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 0px 24px 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-valuation-record-method-dialog-content__title {
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      margin-bottom: 22px;
    }

    .el-form-item {
      margin-bottom: 0;

      .el-select .el-select__wrapper {
        width: 432px;
      }
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    padding-bottom: 20px;

    .el-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      width: 113px;
      background: #1753eb;
      border-radius: 22px;
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #fff;
      border: 0px;
    }
  }
}

.elv-valuation-record-method-select-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
